import {Container} from "@material-ui/core";
import InputForm from "../../components/inputForm";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Helmet} from "react-helmet";

const useStyles = makeStyles((theme) => ({
    inputForm: {
        marginTop: theme.spacing(2),
    }
}));

function Add(props) {
    const classes = useStyles();

    return (
        <Container>
            <Helmet>
                <title>
                    اشتراک‌گذاری تجربه | پیدا
                </title>
            </Helmet>

            <div className={classes.inputForm}>
                <InputForm setSubmitted={props.setSubmitted}/>
            </div>
        </Container>
    )
}

export default Add;