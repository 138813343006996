import React from "react";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import {IconButton} from "@material-ui/core";

const MyTooltip = withStyles((theme) => ({
    tooltip: {
        fontSize: 15,
        textAlign: "center",
    },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
    alignMiddle: {
        verticalAlign: "middle"
    },
}));

const message = "داده‌های قدیمی‌تر بر اساس میزان افزایش حقوق رسمی هر سال به‌روز شده‌اند." +
    " برای اطلاعات بیشتر به صفحه سوالات متداول مراجعه کنید.";

function InfoTooltipIcon(props) {
    const classes = useStyles();
    return (
        <React.Fragment>
            <MyTooltip title={message} placement={"top"} enterTouchDelay={0} interactive>
                <IconButton size={"small"}>
                    <InfoOutlinedIcon fontSize={"inherit"} color={"primary"} className={classes.alignMiddle}/>
                </IconButton>
            </MyTooltip>
        </React.Fragment>
    )
}

export default InfoTooltipIcon;