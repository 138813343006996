import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    paper: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
    },
    answer: {
        marginTop: theme.spacing(1)
    },
    question: {
        marginTop: theme.spacing(4)
    },
}));

function InputHelp(props) {
    const classes = useStyles();
    return (
        <Paper variant="outlined" square className={classes.paper}>
            <Typography variant="h6" component="h6">
                <b>
                    چگونه اطلاعات من ناشناس خواهد بود؟
                </b>
            </Typography>
            <Typography variant="body1" component="p" className={classes.answer}>
                پیدا از شما هیچ گونه اطلاعات شخصی درخواست نمی‌کند. بنابراین اطلاعات شخصی کاربران در اختیار پیدا قرار
                نمی‌گیرد.
            </Typography>
            <Typography variant="h6" component="h6" className={classes.question}>
                <b>
                    اطلاعات را واقعی نگه دارید
                </b>
            </Typography>
            <Typography variant="body1" component="div" className={classes.answer}>
                از شما سپاس‌گزاریم که کمک می‌کنید پیدا مکانی قابل اعتماد برای کاربران باشد.
                لطفا نظرات خود را مطابق با واقعیت بنویسید و از نوشتن موارد زیر پرهیز کنید:
                <ul>
                    <li>
                        اطلاعات محرمانه و شخصی
                    </li>
                    <li>
                        کلمات ناشایست
                    </li>
                </ul>
                برای اطلاعات بیشتر
                {" "}
                <a href={'/terms'} target="_blank" rel="noopener noreferrer">
                    قوانین پیدا
                </a>
                {" "}
                را ببینید.
            </Typography>
        </Paper>
    )
}

export default InputHelp;