import React from "react";
import {IconButton} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {useHistory} from "react-router-dom";

export function HeaderMenu() {
    let history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const goToFAQ = () => {
        history.push("/faq");
        setAnchorEl(null);
    }

    const goToTerms = () => {
        history.push("/terms");
        setAnchorEl(null);
    }

    const goToAbout = () => {
        history.push("/about");
        setAnchorEl(null);
    }

    return (
        <div>
            <IconButton edge="end" color="inherit" aria-label="menu" onClick={handleClick}>
                <MenuIcon/>
            </IconButton>
            <Menu
                id="top-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={goToFAQ}>
                    سوالات متداول
                </MenuItem>
                <MenuItem onClick={goToTerms}>
                    قوانین
                </MenuItem>
                <MenuItem onClick={goToAbout}>
                    درباره ما
                </MenuItem>
            </Menu>
        </div>
    )
}