import Typography from "@material-ui/core/Typography";
import persianJs from "persianjs";
import Rating from "@material-ui/lab/Rating";
import React from "react";

function ReviewsTitle(props) {
    return (
        <React.Fragment>
            <Typography variant={"h5"} component={"span"}>
                <b>
                    {props.company["avg_overall_rating"] &&
                    persianJs(props.company["avg_overall_rating"].toFixed(1).toString())
                        .englishNumber().toString()}
                    {" "}
                </b>
            </Typography>
            <div style={{ verticalAlign: "top", display: "inline-flex" }}>
                {props.company["avg_overall_rating"] &&
                <Rating name={"overall-rating"} value={props.company["avg_overall_rating"]}
                        size={"large"} precision={0.1} readOnly/>
                }
            </div>
        </React.Fragment>
    )
}

export default ReviewsTitle;