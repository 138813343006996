import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import {Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import StarIcon from '@material-ui/icons/Star';
import Hidden from "@material-ui/core/Hidden";
import persianJs from "persianjs";
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    box: {
        background: '#ededed',
        borderRadius: theme.shape.borderRadius,
        padding: '10px 10px',
    },
    logoFrame: {
        background: "white",
        height: 80,
        width: 80,
        padding: '10px 10px',
    },
    companyLogo: {
        maxWidth: "100%",
        maxHeight: "100%",
        position: "relative",
        top: "50%",
        transform: "translateY(-50%)",
    },
    center: {
        textAlign: "center"
    },
    link: {
        textDecoration: "none",
        color: theme.palette.text.primary,
    },
    iconVerticalAlign: {
        verticalAlign: "middle"
    }
}));

function Company(props) {
    const classes = useStyles();

    if (!props.company) {
        return <div/>
    }

    const rating = props.company['avg_overall_rating'];
    const companyLink = props.company['slug'] ? `/companies/${props.company['slug']}/` : '#';
    return (
        <Link to={companyLink} className={classes.link}>
            <div className={classes.box}>
                <Grid container spacing={0} alignItems="center">
                    <Grid item xs={7} sm={4}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item>
                                <div className={classes.logoFrame}>
                                    <img src={props.company['logo']} alt={props.company['name_fa']} className={classes.companyLogo}/>
                                </div>
                            </Grid>
                            <Grid item xs>
                                <div>
                                    <Typography variant="h5" component="h5">
                                        {props.company['name_fa']}
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                        <LocationOnIcon fontSize="inherit" className={classes.iconVerticalAlign}/>
                                        {" "}
                                        {props.company['headquarters']['city']}
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={5} sm={8}>
                        <Grid container spacing={1}>
                            <Grid item xs={6} sm={4}>
                                {rating &&
                                <Typography variant="h5" component="div" align="center">
                                    {persianJs(rating.toFixed(1).toString()).englishNumber().toString()}
                                    {" "}
                                    <StarIcon fontSize="inherit" htmlColor="#ffb400" className={classes.iconVerticalAlign}/>
                                </Typography>
                                }
                            </Grid>
                            <Grid item xs={6} sm={4}>
                                <div className={classes.center}>
                                    <Typography variant="h5" component="span" align="center">
                                        {persianJs(props.company['salary_count'].toString()).englishNumber().toString()}
                                        {" "}
                                    </Typography>
                                    <Typography variant="body1" component="span">
                                        حقوق
                                    </Typography>
                                </div>
                            </Grid>
                            <Hidden xsDown>
                                <Grid item sm={4}>
                                    <div className={classes.center}>
                                        <Typography variant="h5" component="span">
                                            {persianJs(props.company['review_count'].toString()).englishNumber().toString()}
                                            {" "}
                                        </Typography>
                                        <Typography variant="body1" component="span">
                                            نظر
                                        </Typography>
                                    </div>
                                </Grid>
                            </Hidden>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </Link>
    )
}

export default Company;