import Typography from "@material-ui/core/Typography";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import EmailIcon from '@material-ui/icons/Email';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import TelegramIcon from '@material-ui/icons/Telegram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import {Helmet} from "react-helmet";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(4),
    },
    iconVerticalAlign: {
        verticalAlign: "middle"
    }
}));

function About(props) {
    const classes = useStyles();
    return (
        <Container maxWidth="md">
            <Helmet>
                <title>
                    درباره ما | پیدا
                </title>
            </Helmet>

            <div className={classes.root}>
                <Typography variant="h4" component="p">
                    <b>
                        درباره پیدا
                    </b>
                </Typography>
                <br/>
                <Typography variant="body1" component="p" align="justify">
                    پیدا با هدف ایجاد شفافیت در بازار کار ایران شروع به فعالیت کرد. ما فکر می‌کنیم ایجاد شفافیت می‌تواند
                    باعث بهبود شرایط موجود برای شرکت‌ها و افراد شود.
                </Typography>
                <br/>
                <Typography variant="body1" component="p" align="justify">
                    پیدا بر اساس تجربیات افراد شکل گرفته‌است. افراد با به اشتراک گذاشتن تجربیات کاری خود در شرکت‌های
                    مختلف،‌ به دیگران کمک می‌کنند تا بتوانند شغل مورد نظرشان را بهتر کنند و در مقیاس بزرگ‌تر باعث پیشرفت
                    و بهتر شدن فضای کاری در ایران می‌شوند.
                </Typography>
                <br/> <br/>
                <Typography variant="h4" component="p">
                    <b>
                        تماس با ما
                    </b>
                </Typography>
                <br/>
                <Typography variant="body1" component="p" align="justify">
                    <EmailIcon fontSize={"inherit"} className={classes.iconVerticalAlign}/>
                    {" "}
                    نشانی ایمیل:
                    {" "}
                    <a href="mailto:info@peydaa.ir">
                        info@peydaa.ir
                    </a>
                    <br/>
                    <InstagramIcon fontSize={"inherit"} className={classes.iconVerticalAlign}/>
                    {" "}
                    اینستاگرام:
                    {" "}
                    <a href={"https://www.instagram.com/peydaa.ir/"}>
                        peydaa.ir
                    </a>
                    <br/>
                    <TwitterIcon fontSize={"inherit"} className={classes.iconVerticalAlign}/>
                    {" "}
                    توییتر:
                    {" "}
                    <a href={"https://twitter.com/peydaa_ir"}>
                        peydaa_ir
                    </a>
                    <br/>
                    <TelegramIcon fontSize={"inherit"} className={classes.iconVerticalAlign}/>
                    {" "}
                    تلگرام:
                    {" "}
                    <a href={"https://t.me/peydaair"}>
                        peydaair
                    </a>
                    <br/>
                    <LinkedInIcon fontSize={"inherit"} className={classes.iconVerticalAlign}/>
                    {" "}
                    لینکدین:
                    {" "}
                    <a href={"https://www.linkedin.com/company/peydaa/"}>
                        peydaa
                    </a>
                </Typography>
            </div>
        </Container>
    )
}

export default About;