function getCompanySizeDescription(size) {
    const data = {
        "A": "۱ تا ۱۰ نفر",
        "B": "۱۱ تا ۵۰ نفر",
        "C": "۵۱ تا ۲۰۰ نفر",
        "D": "۲۰۱ تا ۵۰۰ نفر",
        "E": "۵۰۱ تا ۱۰۰۰ نفر",
        "F": "۱۰۰۱ تا ۵۰۰۰ نفر",
        "G": "۵۰۰۱ تا ده هزار نفر",
        "H": "بیش از ده هزار نفر"
    };
    return data[size]
}

export default getCompanySizeDescription;