import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    buttonText: {
        textDecoration: 'none',
        color: theme.palette.text.primary,
    },
}));

export function HeaderItems() {
    const classes = useStyles();
    return (
        <div>
            <Link to="/faq" className={classes.buttonText}>
                <Button color="inherit" size={"large"}>
                    سوالات متداول
                </Button>
            </Link>
            <Link to="/terms" className={classes.buttonText}>
                <Button color="inherit" size={"large"}>
                    قوانین
                </Button>
            </Link>
            <Link to="/about" className={classes.buttonText}>
                <Button color="inherit" size={"large"}>
                    درباره ما
                </Button>
            </Link>
        </div>
    )
}