import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import persianJs from "persianjs";
import getCompanySizeDescription from "../../../api";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(3),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
    },
    fullWidth: {
        width: '100%',
    },
    tableHeader: {
        width: 100,
    },
    companyDesc: {
        marginTop: theme.spacing(1),
    },
}));

function Description(props) {
    const classes = useStyles();
    const readableWebsiteURL = props.company["website_url"] ?
        props.company["website_url"].replace("http://", "").replace("https://", "").replace("www.", "").replace("/", "") : "";

    return (
        <Paper variant={"outlined"} square className={classes.paper}>
            <Grid container spacing={0}>
                <Grid item xs={12} sm={4}>
                    <Typography variant={"body1"} component={"div"}>
                        <table className={classes.fullWidth}>
                            <tbody>
                            <tr>
                                <td className={classes.tableHeader}>
                                    <b>
                                        وبسایت:
                                    </b>
                                </td>
                                <td>
                                    <a href={props.company["website_url"] || "#"} target="_blank"
                                       rel="noopener noreferrer">
                                        {readableWebsiteURL}
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td className={classes.tableHeader}>
                                    <b>
                                        تعداد کارکنان:
                                    </b>
                                </td>
                                <td>
                                    {getCompanySizeDescription(props.company["size"]) || "-"}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Typography variant={"body1"} component={"div"}>
                        <table className={classNames(classes.fullWidth, classes.table)}>
                            <tbody>
                            <tr>
                                <td className={classes.tableHeader}>
                                    <b>
                                        صنعت:
                                    </b>
                                </td>
                                <td>
                                    {props.company["industry"] && props.company["industry"]["name_fa"]}
                                </td>
                            </tr>
                            <tr>
                                <td className={classes.tableHeader}>
                                    <b>
                                        دفتر مرکزی:
                                    </b>
                                </td>
                                <td>
                                    {props.company["headquarters"] && props.company["headquarters"]["city"]}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Typography variant={"body1"} component={"div"}>
                        <table className={classNames(classes.fullWidth, classes.table)}>
                            <tbody>
                            <tr>
                                <td className={classes.tableHeader}>
                                    <b>
                                        تاسیس:
                                    </b>
                                </td>
                                <td>
                                    {(props.company["founded_year"] &&
                                        persianJs(props.company["founded_year"].toString()).englishNumber().toString())
                                    || "-" }
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={3} className={classes.companyDesc}>
                <Grid item xs={12}>
                    <Typography variant={"body1"} component={"p"}>
                        {props.company["description"]}
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default Description;