import React from "react";
import MainTitle from "./MainTitle";
import LastSalarySubmissions from "./LastSalarySubmissions";
import Container from "@material-ui/core/Container";
import InputForm from "../../components/inputForm";
import Companies from "./Companies";
import {makeStyles} from "@material-ui/core/styles";
import {Helmet} from "react-helmet";

const useStyles = makeStyles((theme) => ({
    inputForm: {
        marginTop: theme.spacing(6),
    }
}));

function Home(props) {
    const classes = useStyles();

    return (
        <Container>
            <Helmet>
                <title>
                    مقایسه حقوق‌ها و شرکت‌ها | پیدا
                </title>
            </Helmet>

            <MainTitle/>
            <LastSalarySubmissions/>
            <Companies/>
            <div className={classes.inputForm}>
                <InputForm setSubmitted={props.setSubmitted}/>
            </div>
        </Container>
    )
}

export default Home;