import Typography from "@material-ui/core/Typography";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    titleHelp: {
        marginTop: theme.spacing(1),
    },
}));

function FormTitle(props) {
    const classes = useStyles();
    return (
        <div>
            <Typography variant="h5" component="p">
                <b>
                    تجربیات خود را با کاربران به اشتراک بگذارید
                </b>
            </Typography>
            <Typography variant="body1" component="p" className={classes.titleHelp}>
                اطلاعات شما به صورت ناشناس به دیگر کاربران کمک می‌کند تا بتوانند شغل مناسب خود را پیدا کنند.
            </Typography>
        </div>
    )
}

export default FormTitle;
