import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import React from "react";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary">
            {'©'}
            {' '}
            تمامی حقوق برای پیدا محفوظ است.
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    footer: {
        padding: theme.spacing(3, 2),
        marginTop: 'auto',
        backgroundColor: theme.palette.grey[100]
    }
}));

function Footer(props) {
    const classes = useStyles();
    return (
        <footer className={classes.footer}>
            <Box display="flex" justifyContent="center">
                <Copyright />
            </Box>
        </footer>
    )
}

export default Footer;