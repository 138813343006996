import Button from "@material-ui/core/Button";
import classNames from "classnames";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginTop: theme.spacing(3),
    },
    fullWidth: {
        width: '100%',
    },
}));

function SubmitButton(props) {
    const classes = useStyles();
    return (
        <Button variant="contained" color="primary" className={classNames(classes.formControl, classes.fullWidth)}
                onClick={props.onSubmit}>
            <b>
                ارسال
            </b>
        </Button>
    )
}

export default SubmitButton;
