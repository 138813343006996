import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {AccessTime, Business, Work} from "@material-ui/icons";
import React from "react";
import persianJs from "persianjs";
import {Skeleton} from "@material-ui/lab";

function verboseTimeDuration(duration) {
    const days = Math.floor(duration / (1000 * 60 * 60 * 24));
    const hours = Math.floor((duration % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((duration % (1000 * 60)) / 1000);

    let res = "";
    if (days > 0) {
        res = res + days;
        res = `${res} روز`;
        return res;
    }
    if (hours > 0) {
        if (res.length > 0) {
            res = res + ' و ';
        }
        res = res + hours;
        res = `${res} ساعت`;
        return res;
    }
    if (minutes > 0) {
        if (res.length > 0) {
            res = res + ' و ';
        }
        res = res + minutes;
        res = `${res} دقیقه`;
        return res;
    }
    if (seconds > 0) {
        if (res.length > 0) {
            res = res + ' و ';
        }
        res = res + seconds;
        res = `${res} ثانیه`;
        return res;
    }
    return res;
}

const useStyles = makeStyles((theme) => ({
    box: {
        background: '#ededed',
        borderRadius: theme.shape.borderRadius,
        padding: '10px 10px',
    },
    timestamp: {
        textAlign: "center",
        fontSize: "small",
    },
    salaryCompany: {
        textAlign: "center",
        marginTop: '12px',
    },
    salaryJob: {
        textAlign: "center",
        marginTop: '-4px',
        marginBottom: 0,
    },
    iconVerticalAlign: {
        verticalAlign: "middle"
    }
}));

function SalarySubmission(props) {
    const classes = useStyles();
    const timeDiff = (props.salary && props.salary['created_at']) ? new Date(Date.now() - Date.parse(props.salary['created_at'])) : undefined;
    const formattedTimeDiff = persianJs(verboseTimeDuration(timeDiff) + " پیش").englishNumber().toString();
    return (
        <div className={classes.box}>
            <Typography variant="body1" component="p" className={classes.timestamp}>
                {props.salary ? (
                    <React.Fragment>
                        <AccessTime fontSize="inherit" className={classes.iconVerticalAlign}/>
                        {" "}
                        {formattedTimeDiff}
                    </React.Fragment>
                ) : (
                    <Skeleton/>
                )}
            </Typography>
            <Typography variant="body1" component="p" className={classes.salaryCompany}>
                {(props.salary && props.salary["employer"]) ? (
                    <React.Fragment>
                        <Business fontSize="inherit" className={classes.iconVerticalAlign}/>
                        {" "}
                        {props.salary["employer"]["name_fa"]}
                    </React.Fragment>
                ) : (
                    <Skeleton className={classes.salaryCompany}/>
                )}
            </Typography>
            <Typography variant="body1" component="p" className={classes.salaryJob}>
                {(props.salary && props.salary["job_category"]) ? (
                    <React.Fragment>
                        <Work fontSize="inherit" className={classes.iconVerticalAlign}/>
                        {" "}
                        {props.salary["job_category"]["name_fa"]}
                    </React.Fragment>
                ) : (
                    <Skeleton className={classes.salaryJob}/>
                )}
            </Typography>
        </div>
    )
}

export default SalarySubmission;
