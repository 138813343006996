import Grid from "@material-ui/core/Grid";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import persianJs from "persianjs";
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down('xs')]: {
            paddingTop: '1rem',
        },
    },
    barContainer: {
        display: "flex",
        flexDirection: "row-reverse"
    },
    baseBar: {
        position: "relative",
        height: "1.5rem",
        width: "100%",
        backgroundColor: theme.palette.primary.main,
    },
    activeBar: {
        position: "absolute",
        height: "1.5rem",
        backgroundColor: theme.palette.primary.dark,
        borderLeft: "2px solid white",
        borderRight: "2px solid white",
    },
    percentileText: {
        fontSize: "0.75rem",
        position: "absolute",
        lineHeight: "1.66",
        width: "3rem",
        textAlign: "center",
        top: "1.5rem",
    },
    boundryText: {
        color: theme.palette.text.primary,
        minWidth: "3rem",
        textAlign: "center",
    },
    chevron: {
        position: "absolute",
        top: "-12px",
        height: 0,
        width: 0,
        transform: "translate(-50%)",
        borderLeft: "8px solid transparent",
        borderRight: "8px solid transparent",
        borderTop: `8px solid ${theme.palette.primary.main}`,
    }
}));

function SalaryFigure({ salary }) {
    const cls = useStyles();

    if (salary.count < 5) {
        return null;
    }

    const minMillion = salary.minimum / (10 ** 6);
    const lowMillion = salary.low / (10 ** 6);
    const highMillion = salary.high / (10 ** 6);
    const maxMillion = salary.maximum / (10 ** 6);

    const total = salary.maximum - salary.minimum;
    const left = (salary.low - salary.minimum) * 100 / total;
    const width = (salary.high - salary.low) * 100 / total;
    const chevLeft = (salary.amount - salary.minimum) * 100 / total;

    return (
        <Grid item xs={12} className={cls.container}>
            <div className={cls.barContainer}>
                <Tooltip title="کمترین" arrow>
                    <Typography variant={"body1"} component={"span"} className={cls.boundryText}>
                        {persianJs(minMillion.toFixed(1).toString()).englishNumber().toString()}
                    </Typography>
                </Tooltip>
                <div className={cls.baseBar}>
                    <Tooltip title="چارک اول" arrow>
                        <Typography variant={"caption"} component={"span"} className={cls.percentileText} style={{ left: `calc(${left}% - 1.5rem + 1px)` }}>
                            {persianJs(lowMillion.toFixed(1).toString()).englishNumber().toString()}
                        </Typography>
                    </Tooltip>
                    <div className={cls.activeBar} style={{ width: `${width}%`, left: `${left}%` }}></div>
                    <Tooltip title="چارک سوم" arrow>
                        <Typography variant={"caption"} component={"span"} className={cls.percentileText} style={{ left: `calc(${left + width}% - 1.5rem + 1px)` }}>
                            {persianJs(highMillion.toFixed(1).toString()).englishNumber().toString()}
                        </Typography>
                    </Tooltip>
                    <Tooltip title="میانگین" arrow>
                        <span className={cls.chevron} style={{ left: `calc(${chevLeft}% - 4px)` }}></span>
                    </Tooltip>
                </div>
                <Tooltip title="بیشترین" arrow>
                    <Typography variant={"body1"} component={"span"} className={cls.boundryText}>
                        {persianJs(maxMillion.toFixed(1).toString()).englishNumber().toString()}
                    </Typography>
                </Tooltip>
            </div>
        </Grid>
    )
}

export default SalaryFigure;