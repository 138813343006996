import {alpha, withStyles} from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";

const FormToggleButton = withStyles((theme) => ({
    root: {
        border: `1px solid ${alpha(theme.palette.action.active, 0.23)}`,
        color: alpha(theme.palette.action.active, 0.54),
    },
    sizeSmall: {
        fontSize: theme.typography.pxToRem(15),
    },
}))(ToggleButton);

export default FormToggleButton;
