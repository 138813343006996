import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {Grid, Hidden} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import SalarySubmission from "./SalarySubmission";
import {getSalaries} from "../../api";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(6)
    },
    boxesRow: {
        marginTop: theme.spacing(2)
    }
}));

function LastSalarySubmissions() {
    const classes = useStyles();
    const [salaries, setSalaries] = useState([]);

    useEffect(() => {
        getSalaries().then(data => setSalaries(data))
    }, []);

    return (
        <div className={classes.root}>
            <Typography variant="h4" component="h5">
                <b>
                    آخرین حقوق‌های ثبت‌شده
                </b>
            </Typography>
            <Grid container spacing={1} className={classes.boxesRow}>
                <Grid item xs={6} sm={4} md={2}>
                    <SalarySubmission salary={salaries[0]}/>
                </Grid>
                <Grid item xs={6} sm={4} md={2}>
                    <SalarySubmission salary={salaries[1]}/>
                </Grid>
                <Hidden xsDown>
                    <Grid item xs={4} md={2}>
                        <SalarySubmission salary={salaries[2]}/>
                    </Grid>
                </Hidden>
                <Hidden smDown>
                    <Grid item xs={2}>
                        <SalarySubmission salary={salaries[3]}/>
                    </Grid>
                    <Grid item xs={2}>
                        <SalarySubmission salary={salaries[4]}/>
                    </Grid>
                    <Grid item xs={2}>
                        <SalarySubmission salary={salaries[5]}/>
                    </Grid>
                </Hidden>
            </Grid>
        </div>
    )
}

export default LastSalarySubmissions;
