import {Backdrop, CircularProgress, Grid} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import React, {useEffect, useState} from "react";
import InputHelp from "./InputHelp";
import Divider from "@material-ui/core/Divider";
import validateJS from 'validate.js';
import persianJs from "persianjs";
import FormTitle from "./FormTitle";
import SalaryForm from "./SalaryForm";
import ReviewForm from "./ReviewForm";
import SubmitButton from "./SubmitButton";
import { useHistory, useLocation } from "react-router-dom";
import {createReview, createSalary} from "../../api";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(3, 2, 3, 2),
    },
    formControl: {
        marginTop: theme.spacing(3),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function InputForm(props) {
    const [job, setJob] = useState("");
    const [company, setCompany] = useState("");
    const [amount, setAmount] = useState("");
    const [isCurrentJob, setIsCurrentJob] = useState("");
    const [lastYear, setLastYear] = useState("");
    const [yearsOfExp, setYearsOfExp] = useState("");
    const [city, setCity] = useState("");
    const [wantReview, setWantReview] = useState("");
    const [rating, setRating] = useState("");
    const [reviewHeadline, setReviewHeadline] = useState("");
    const [pros, setPros] = useState("");
    const [cons, setCons] = useState("");
    const [errors, setErrors] = useState(undefined);
    const [backdropOpen, setBackdropOpen] = useState(false);
    let history = useHistory();
    let query = useQuery();

    const queryCompany = query.get("company");

    useEffect(() => {
        if (queryCompany) {
            setCompany(queryCompany);
        }
    }, [queryCompany, ]);

    const constrains = {
        job: {
            presence: {
                allowEmpty: false,
                message: "شغل نمی‌تواند خالی باشد",
            }
        },
        company: {
            presence: {
                allowEmpty: false,
                message: "شرکت نمی‌تواند خالی باشد",
            }
        },
        amount : {
            presence: {
                allowEmpty: false,
                message: "پایه حقوق نمی‌تواند خالی باشد",
            },
            numericality: {
                greaterThanOrEqualTo: 0,
                notValid: "پایه حقوق باید یک عدد باشد",
                notGreaterThanOrEqualTo: "پایه حقوق نمی‌تواند منفی باشد",
            }
        },
        isCurrentJob: {
            presence: {
                message: "وضعیت شغلی نمی‌تواند خالی باشد",
            }
        },
        yearsOfExp: {
            numericality: {
                greaterThanOrEqualTo: 0,
                notValid: "سابقه کار باید یک عدد باشد",
                notGreaterThanOrEqualTo: "سابقه کار نمی‌تواند منفی باشد",
            }
        },
        lastYear: {
            presence: function(value, attribute, validatorOptions, attributes, globalOptions) {
                return (isCurrentJob === true) ? false : {
                    allowEmpty: false,
                    message: "آخرین سال فعالیت نمی‌تواند خالی باشد",
                }
            }
        },
        rating: {
            presence: function(value, attribute, validatorOptions, attributes, globalOptions) {
                return !wantReview ? false : {
                    allowEmpty: false,
                    message: "امتیاز نمی‌تواند خالی باشد",
                }
            }
        },
        reviewHeadline: {
            presence: function(value, attribute, validatorOptions, attributes, globalOptions) {
                return !wantReview ? false : {
                    allowEmpty: false,
                    message: "عنوان نظر نمی‌تواند خالی باشد",
                }
            }
        },
    };

    const validate = () => {
        // console.log(job, company, amount, city);
        const result = validateJS({
            job: job,
            company: company,
            amount: (amount ? parseFloat(persianJs(amount).toEnglishNumber()) : amount),
            isCurrentJob: isCurrentJob,
            yearsOfExp: (yearsOfExp ? parseFloat(persianJs(yearsOfExp).toEnglishNumber()) : yearsOfExp),
            lastYear: lastYear,
            rating: rating,
            reviewHeadline: reviewHeadline,
        }, constrains, {fullMessages: false});
        setErrors(result);
        return result === undefined;
    };

    const postData = async () => {
        const ONE_MILLION = 1000 * 1000;
        await createSalary({
            "amount": parseFloat(persianJs(amount).toEnglishNumber()) * ONE_MILLION,
            "job_title_request": job,
            "employer_name_request": company,
            "location_city_request": city,
            "to_year": isCurrentJob ? 0 : lastYear,
            "years_of_experience": yearsOfExp,
        });
        if (wantReview) {
            await createReview({
                "job_title_request": job,
                "employer_name_request": company,
                "location_city_request": city,
                "to_year": isCurrentJob ? 0 : lastYear,
                "overall_rating": rating,
                "headline": reviewHeadline,
                "pros": pros,
                "cons": cons,
            });
        }
    };

    const onSubmit = () => {
        if (!validate()) {
            return;
        }
        setBackdropOpen(true);
        postData().then(() => {
            props.setSubmitted(true);
            history.push('/success');
        }).catch(err => {
            setBackdropOpen(false);
            console.log(err); // TODO
        })
    };

    const classes = useStyles();
    return (
        <React.Fragment>
            <Backdrop className={classes.backdrop} open={backdropOpen}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Grid container spacing={2} className={classes.root}>
                <Grid item xs={12} md={8}>
                    <Paper variant="outlined" square className={classes.paper}>
                        <Grid container>
                            <Grid item sm={1} />
                            <Grid item xs>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <FormTitle/>
                                        <SalaryForm
                                            job={job} setJob={setJob}
                                            company={company} setCompany={setCompany}
                                            amount={amount} setAmount={setAmount}
                                            isCurrentJob={isCurrentJob} setIsCurrentJob={setIsCurrentJob}
                                            lastYear={lastYear} setLastYear={setLastYear}
                                            yearsOfExp={yearsOfExp} setYearsOfExp={setYearsOfExp}
                                            city={city} setCity={setCity}
                                            errors={errors}
                                        />
                                        <Divider className={classes.formControl} />
                                        <ReviewForm
                                            wantReview={wantReview} setWantReview={setWantReview}
                                            rating={rating} setRating={setRating}
                                            reviewHeadline={reviewHeadline} setReviewHeadline={setReviewHeadline}
                                            pros={pros} setPros={setPros}
                                            cons={cons} setCons={setCons}
                                            errors={errors}
                                        />
                                    </Grid>

                                    <Grid item xs={10}/>
                                    <Grid item xs>
                                        <SubmitButton onSubmit={onSubmit}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sm={1} />
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                    <InputHelp/>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default InputForm;
