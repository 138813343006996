import React, {useEffect, useState} from 'react';
import './App.css';
import 'react-circular-progressbar/dist/styles.css';
import {makeStyles} from "@material-ui/core/styles";
import Header from "../components/header";
import Footer from "../components/Footer";
import Home from "./home";
import {
    Router,
    Switch,
    Route,
} from "react-router-dom";
import About from "./about";
import Success from "./success";
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
import CompanyList from "./companyList";
import CompanyDetail from "./companyDetail";
import Add from "./add";
import ScrollToTop from "../components/ScrollToTop";
import Terms from "./terms";
import FAQ from "./faq";

const useStyles = makeStyles((theme) => ({
    root: {
        background: 'white',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
    content: {}
}));

const history = createBrowserHistory();

// Initialize google analytics page view tracking
history.listen(location => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

function App() {
    const classes = useStyles();
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_CODE, {
            testMode: process.env.NODE_ENV !== 'production',
            // debug: true,
        });
        ReactGA.pageview(window.location.pathname + window.location.search);
    });

    return (
        <div className={classes.root}>
            <Router history={history}>
                <ScrollToTop>
                    <Header/>
                    <div className={classes.content}>
                        <Switch>
                            <Route path="/companies/:slug/:tab?">
                                <CompanyDetail/>
                            </Route>
                            <Route path="/companies">
                                <CompanyList/>
                            </Route>
                            <Route path="/terms">
                                <Terms/>
                            </Route>
                            <Route path="/faq">
                                <FAQ/>
                            </Route>
                            <Route path="/about">
                                <About/>
                            </Route>
                            <Route path="/success">
                                <Success submitted={submitted}/>
                            </Route>
                            <Route path="/add">
                                <Add setSubmitted={setSubmitted}/>
                            </Route>
                            <Route path="/">
                                <Home setSubmitted={setSubmitted}/>
                            </Route>
                        </Switch>
                    </div>
                    <Footer/>
                </ScrollToTop>
            </Router>
        </div>
    );
}

export default App;
