import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { faIR } from '@material-ui/core/locale';


let theme = createTheme({
    direction: "rtl",
    palette: {
        primary: {
            main: '#1D71B8'
        }
    },
    typography: {
        fontFamily: [
            'Vazir',
            'sans-serif',
        ].join(','),
    },
}, faIR);

theme = responsiveFontSizes(theme);

export default theme;