import Typography from "@material-ui/core/Typography";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import {Helmet} from "react-helmet";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(4),
    }
}));

function Terms(props) {
    const classes = useStyles();
    return (
        <Container maxWidth="md">
            <Helmet>
                <title>
                    قوانین | پیدا
                </title>
            </Helmet>

            <div className={classes.root}>
                <Typography variant="h4" component="p">
                    <b>
                        قوانین پیدا
                    </b>
                </Typography>
                <br/>
                <Typography variant="body1" component="p" align="justify">
                    پیدا بستری را فراهم کرده است تا همگی بتوانیم تجربیات کاری خودمان را با هم به اشتراک بگذاریم و با در
                    دسترس قرار دادن آنها برای همه، افراد بتوانند انتخاب بهتری در مورد محل کارشان داشته باشند. در پیدا ما
                    به کاربرانی که تجربیاتشان را به صورت ناشناس با ما به اشتراک می‌گذارند اعتماد داریم چرا که پیدا با
                    تجربیات کاربرانمان رشد می‌کند و می‌تواند مفید باشد اما در این راه دوست داریم که موارد زیر را همه با
                    هم رعایت کنیم:
                </Typography>
                <Typography variant="body1" component="div" align="justify">
                    <ul>
                        <li>
                            تجربیات کاری که شامل اطلاعات شخصی و خصوصی افراد و یا سازمان باشند در پیدا منتشر نمی‌شوند.
                        </li>
                        <li>
                            تجربیاتی که شامل اسم افراد و اطلاعاتی در مورد افراد هستند منتشر نمی‌شوند.
                        </li>
                        <li>
                            تجربیاتی که در آنها از کلمات نامناسب و ناشایست استفاده شده است منتشر نمی‌شود.
                        </li>
                        <li>
                            تجربیاتی که در آن با ذکر نام، سازمان‌ها را با یکدیگر مقایسه می‌کند منتشر نمی‌شود. مانند:
                            «من در شرکت x کار می‌کنم ولی به نظرم شرکت y برای کار مناسب‌تر است.»
                        </li>
                        <li>
                            تجربیاتی که در آن‌ها اتهامی به شخص یا سازمانی وارد شود منتشر نمی‌شود. مانند:
                            «شرکتی که من در آن کار می‌کنم مدیرش کلاهبردار است.»
                        </li>
                    </ul>
                </Typography>

                <Typography variant="body1" component="p" align="justify">
                    پیدا اعتماد و اطمینان دارد که کاربران اطلاعات دقیق و درست را در اختیار آن قرار می‌دهند تا همه با هم
                    کمک کنیم شفافیت بیشتر و بهتری در محیط کار داشته باشیم چرا که هم باعث حفظ حقوق کارمندان می‌شود، و هم
                    به بهتر شدن کارفرمایان کمک می‌کند.
                </Typography>
                <br/>
                <Typography variant="body1" component="p" align="justify">
                    پیدا مسئولیتی در قبال صحت اطلاعات وارد شده ندارد اما تمام تلاش خود را می‌کند تا با در نظر گرفتن
                    راه‌هایی از انتشار اطلاعات جعلی و غیرواقعی جلوگیری کند.
                    اطلاعاتی که کاربران در پیدا وارد می‌کنند قبل از انتشار توسط پیدا به منظور جلوگیری از اطلاعات شخصی،
                    محرمانه و همچنین ناشایست بررسی می‌شود و در صورت وجود این موارد از انتشار تجربیات جلوگیری می‌کند.
                    پیدا به هیچ عنوان در تجربیات کاربران دخل و تصرفی نخواهد داشت و تمام تجربیات را مطابق با آنچه وارد
                    شده است، منتشر می‌کند مگر حاوی یکی از موارد ذکر شده در بالا باشد.
                    برای حفظ امنیت کاربران،‌ نتایج دسته‌های شغلی با کمتر از سه داده‌ی میزان حقوق با کمی تغییر در پیدا
                    منتشر خواهد شد.
                </Typography>
                <br/>
                <Typography variant="body1" component="p" align="justify">
                    پیدا تمام تلاش خود را می‌کند تا از انتشار هرگونه دیتای جعلی و غیر واقعی جلوگیری کند و در این مسیر از
                    کاربران خود می‌خواهد تا در قسمت گزارش تجربیات و یا تماس با پیدا از طریق ایمیل موارد جعلی را به ما
                    منتقل کنند. پیدا با حداکثر تلاش خود این موارد را بررسی خواهد کرد.
                </Typography>
            </div>
        </Container>
    )
}

export default Terms;