import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import persianJs from "persianjs";
import SalaryFigure from "./SalaryFigure";

const useStyles = makeStyles((theme) => ({
    alignLeft: {
        textAlign: "right",
    },
}));

function Salary({ salary, mobile }) {
    const classes = useStyles();
    const amountMillion = salary.amount ? salary.amount / (10 ** 6) : 0;

    const getSalaryFigure = () => {
        return (
            <Grid item xs={12} sm={6} className={classes.alignLeft}>
                <SalaryFigure salary={salary} mobile={mobile}></SalaryFigure>
            </Grid>
        )
    }

    return (
        <Grid container spacing={0}>
            <Grid item xs={6} sm={3}>
                <Typography variant={"body1"} component={"div"}>
                    {(salary.job_category && salary.job_category.name_fa) || (salary.employer && salary.employer.name_fa)}
                </Typography>
                <Typography variant={"caption"} component={"div"}>
                    بر اساس
                    {" "}
                    {persianJs(salary.count.toString()).englishNumber().toString()}
                    {" "}
                    حقوق ثبت‌شده
                </Typography>
            </Grid>
            {(!mobile && getSalaryFigure())}
            <Grid item xs={6} sm={3} className={classes.alignLeft}>
                <Typography variant={"h6"} component={"span"}>
                    {persianJs(amountMillion.toFixed(1).toString()).englishNumber().toString()}
                    {" "}
                </Typography>
                <Typography variant={"caption"} component={"span"}>
                    میلیون تومان
                </Typography>
            </Grid>
            {(mobile && getSalaryFigure())}
        </Grid>
    )
}

export default Salary;