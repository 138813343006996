import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import {Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import persianJs from "persianjs";
import {Link} from "react-router-dom";
import {Skeleton} from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    box: {
        background: '#ededed',
        borderRadius: theme.shape.borderRadius,
        padding: '10px 10px',
    },
    logoFrame: {
        background: "white",
        height: 80,
        width: 80,
        padding: '10px 10px',
    },
    companyLogo: {
        maxWidth: "100%",
        maxHeight: "100%",
        position: "relative",
        top: "50%",
        transform: "translateY(-50%)",
    },
    link: {
        textDecoration: "none",
        color: theme.palette.text.primary,
    },
    center: {
        textAlign: "center"
    },
    iconVerticalAlign: {
        verticalAlign: "middle"
    }
}));

function Company(props) {
    const classes = useStyles();

    const salary_count = props.company ? props.company['salary_count'] : undefined;
    const companyLink = (props.company && props.company['slug']) ? `/companies/${props.company['slug']}/` : '#';
    return (
        <Link to={companyLink} className={classes.link}>
            <div className={classes.box}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item>
                        {props.company ? (
                            <div className={classes.logoFrame}>
                                <img src={props.company['logo']} alt={props.company['name_fa']} className={classes.companyLogo}/>
                            </div>
                        ) : (
                            <Skeleton variant={"rect"} height={90} width={90}/>
                        )}
                    </Grid>
                    {props.company &&
                    <Grid item xs={6}>
                        <div>
                            <Typography variant="h5" component="p">
                                {props.company['name_fa']}
                            </Typography>
                            <Typography variant="body2" component="p">
                                <LocationOnIcon fontSize="inherit" className={classes.iconVerticalAlign}/>
                                {" "}
                                {props.company['headquarters']['city']}
                            </Typography>
                        </div>
                    </Grid>
                    }
                    <Grid item xs>
                        {salary_count ? (
                            <div className={classes.center}>
                                <Typography variant="h5" component="span" align="center">
                                    {persianJs(props.company['salary_count'].toString()).englishNumber().toString()}
                                    {" "}
                                </Typography>
                                <Typography variant="body1" component="span">
                                    حقوق
                                </Typography>
                            </div>
                        ) : (
                            <React.Fragment>
                                <Typography variant="h5" component="p">
                                    <Skeleton/>
                                </Typography>
                                <Typography variant="body2" component="p">
                                    <Skeleton width="60%"/>
                                </Typography>
                            </React.Fragment>
                        )}
                    </Grid>
                </Grid>
            </div>
        </Link>
    )
}

export default Company;