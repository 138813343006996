import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Salary from "../../../components/Salary";
import persianJs from "persianjs";
import {Link} from "@material-ui/core";
import InfoTooltipIcon from "../../../components/InfoTooltipIcon";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(3),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
    },
    alignLeft: {
        textAlign: "right",
    },
    alignCenter: {
        textAlign: "center",
    },
}));

function Salaries({salaries, company, goToSalariesTab, mobile}) {
    const classes = useStyles();

    const updated = salaries.map(s => s["updated"]).reduce((a, b) => a || b, false);

    return (
        <Paper variant={"outlined"} square className={classes.paper}>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Typography variant={"h5"} component={"h5"}>
                        <b>
                            حقوق‌ها
                        </b>
                    </Typography>
                </Grid>
                <Grid item xs={8} className={classes.alignLeft}>
                    <Typography variant={"h6"} component={"span"}>
                        میانگین در ماه
                        {updated && " "}
                        {updated && <InfoTooltipIcon/>}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                {salaries.slice(0, 2).map((salary, i) =>
                    <React.Fragment key={i}>
                        <Grid item xs={12}>
                            <Salary salary={salary} mobile={mobile} />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                    </React.Fragment>
                )}
                <Grid item xs={12} className={classes.alignCenter}>
                    <Link component={"button"} variant={"body2"} color={"inherit"} onClick={goToSalariesTab}>
                        مشاهده همه
                        {" "}
                        {company["salary_count"] &&
                        persianJs(company["salary_count"].toString()).englishNumber().toString()}
                        {" "}
                        حقوق ثبت‌شده
                    </Link>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default Salaries;