import Description from "./Description";
import Reviews from "./Reviews";
import Salaries from "./Salaries";
import React from "react";

function Overview(props) {
    return (
        <div>
            <Description company={props.company}/>
            <Salaries company={props.company} salaries={props.salaries} goToSalariesTab={props.goToSalariesTab} mobile={props.mobile}/>
            <Reviews company={props.company} reviews={props.reviews} goToReviewsTab={props.goToReviewsTab}/>
        </div>
    )
}

export default Overview;