import axios from "axios";
import getCompanySizeDescription from "./companySize";

const apiURL = process.env.REACT_APP_API_URL;

function genRandStr(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

const requests = axios.create({
    baseURL: apiURL,
    headers: {'X-Peydaa-Header': (() => {
            if (!localStorage.getItem('X-Peydaa-Header')) {
                localStorage.setItem('X-Peydaa-Header', genRandStr(8));
            }
            return localStorage.getItem('X-Peydaa-Header');
        })()},
})

export default getCompanySizeDescription;

export const getSalariesCount = () =>
    requests.get(`/salaries/count/`).then(({ data }) => data);

export const getSalaries = () =>
    requests.get(`/salaries/`).then(({ data }) => data);

export const getJobTitles = () =>
    requests.get(`/job-titles/`).then(({ data }) => data);

export const getCompany = (slug) =>
    requests.get(`/companies/${slug}`).then(({ data }) => data);

export const getCompanyReviews = (slug) =>
    requests.get(`/companies/${slug}/reviews/`).then(({ data }) => data);

export const getCompanySalaries = (slug) =>
    requests.get(`/companies/${slug}/salaries/`).then(({ data }) => data);

export const getCompanies = () =>
    requests.get(`/companies/`).then(({ data }) => data);

export const getCompanyNames = () =>
    requests.get(`/company-names/`).then(({ data }) => data);

export const getLocations = () =>
    requests.get(`/locations/`).then(({ data }) => data);

export const createSalary = (data) =>
    requests.post(`/salaries/`, data).then(({ data }) => data);

export const createReview = (data) =>
    requests.post(`/reviews/`, data).then(({ data }) => data);

export const createSubscriber = (data) =>
    requests.post(`/subscribers/`, data).then(({ data }) => data);

export const createReviewHelpful = (data) =>
    requests.post(`/reviews/helpful/`, data).then(({ data }) => data);

export const deleteReviewHelpful = (id) =>
    requests.delete(`/reviews/helpful/${id}`).then(({ data }) => data);

export const createReviewReport = (data) =>
    requests.post(`/reviews/report/`, data).then(({ data }) => data);

export const getSalaryUpdates = (data) =>
    requests.get(`/salary-updates/`).then(({ data }) => data);
