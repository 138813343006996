import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Salary from "../../components/Salary";
import InfoTooltipIcon from "../../components/InfoTooltipIcon";
import {Helmet} from "react-helmet";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(3),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
    },
    alignLeft: {
        textAlign: "right",
    },
}));

function Salaries({company, salaries, mobile}) {
    const classes = useStyles();

    const updated = salaries.map(s => s["updated"]).reduce((a, b) => a || b, false);

    const getPageTitle = () => {
        let res = 'حقوق‌های شرکت ';
        res += company['name_fa'];
        res += ' | پیدا'
        return res;
    };

    return (
        <Paper variant={"outlined"} square className={classes.paper}>
            {company['name_fa'] &&
            <Helmet>
                <title>
                    {getPageTitle()}
                </title>
            </Helmet>
            }

            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Typography variant={"h5"} component={"h5"}>
                        <b>
                            حقوق‌ها
                        </b>
                    </Typography>
                </Grid>
                <Grid item xs={8} className={classes.alignLeft}>
                    <Typography variant={"h6"} component={"span"}>
                        میانگین در ماه
                        {updated && " "}
                        {updated && <InfoTooltipIcon/>}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                {salaries.map((salary, i) =>
                    <React.Fragment key={i}>
                        <Grid item xs={12}>
                            <Salary salary={salary} mobile={mobile}/>
                        </Grid>
                        {(i < salaries.length - 1) &&
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        }
                    </React.Fragment>
                )}
            </Grid>
        </Paper>
    )
}

export default Salaries;