import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Link} from "react-router-dom";
import {
    Backdrop,
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";
import {getSalaryUpdates} from "../../api";
import persianJs from "persianjs";
import {Helmet} from "react-helmet";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(4),
    },
    table: {
        marginTop: theme.spacing(1),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function OutlinedPaper(props) {
    return (
        <Paper variant={"outlined"}>
            {props.children}
        </Paper>
    )
}

function FAQ(props) {
    const classes = useStyles();
    const [salaryUpdates, setSalaryUpdates] = useState([]);

    useEffect(() => {
        getSalaryUpdates().then(data => setSalaryUpdates(data));
    }, []);

    if (salaryUpdates.length === 0) {
        return (
            <Backdrop className={classes.backdrop} open={true}>
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    }

    return (
        <Container maxWidth="md">
            <Helmet>
                <title>
                    سوالات متداول | پیدا
                </title>
            </Helmet>

            <div className={classes.root}>
                <Typography variant="h4" component="p">
                    <b>
                        سوالات متداول
                    </b>
                </Typography>
                <br/>

                <Typography variant="h6" component="p" align="justify">
                    اطلاعات من با چه کسانی به اشتراک گذاشته می‌شود؟
                </Typography>
                <Typography variant="body1" component="p" align="justify">
                    حقوق‌ها و تجربیاتی را که با «پیدا» به اشتراک می‌گذارید به صورت ناشناس ثبت می‌شوند و با هیچ شرکت یا
                    سازمانی به اشتراک گذاشته نمی‌شوند. از آن‌ها فقط برای تحلیل‌های آماری پیدا استفاده می‌شود که نتایج
                    آن‌ها نیز در اختیار کاربران قرار می‌گیرد.
                </Typography>
                <br/>

                <Typography variant="h6" component="p" align="justify">
                    آیا نظرات در مورد شرکت‌ها را حذف می‌کنید؟
                </Typography>
                <Typography variant="body1" component="p" align="justify">
                    پیدا تمام اطلاعاتی که کاربران در اختیار ما قرار می‌دهند را بدون هیچ گونه تغییری در صورتی که حاوی
                    کلمات ناشایست و اطلاعات محرمانه نباشد منتشر می‌کند. برای توضیحات بیشتر به قسمت
                    {" "}
                    <Link to="/terms">
                        قوانین
                    </Link>
                    {" "}
                    مراجعه کنید.
                </Typography>
                <br/>

                <Typography variant="h6" component="p" align="justify">
                    آیا پیدا صفحه‌ شرکتی را حذف می‌کند؟
                </Typography>
                <Typography variant="body1" component="p" align="justify">
                    در صورتی که پیدا آگاه شود که این شرکت دیگر فعالیت ندارد آن رو حذف خواهد کرد. در غیر این صورت تا
                    زمانی که شرکت یا سازمانی فعالیت داشته باشد تمام تجربیات وارد شده مطابق با قوانین پیدا منتشر خواهد
                    شد.
                </Typography>
                <br/>

                <Typography variant="h6" component="p" align="justify">
                    اطلاعات روی پیدا به چه شکل تایید می‌شوند؟
                </Typography>
                <Typography variant="body1" component="p" align="justify">
                    در وهله‌ی اول پیدا به کاربران خود برای انتشار اطلاعاتی که در اختیار پیدا گذاشته شده است اعتماد
                    می‌کند. پیدا تجربیات کاربران را قبل از انتشار بررسی می‌کند و در صورتی که با قوانین پیدا هم‌راستا
                    نباشد آن‌ها را منتشر نخواهد کرد.
                </Typography>
                <br/>

                {salaryUpdates.length > 0 &&
                <React.Fragment>
                    <Typography variant="h6" component="p" align="justify">
                        حقوق‌های مربوط به سال‌های قبل در پیدا چگونه به‌روزرسانی می‌شوند؟
                    </Typography>
                    <Typography variant="body1" component="p" align="justify">
                        حقوق‌های ثبت‌شده مربوط به سال‌های قبل به طور پیش‌فرض برای همه شرکت‌ها به اندازه میزان تعیین شده توسط
                        وزارت تعاون، کار و رفاه اجتماعی به‌روز می‌شوند. میزان افزایش حقوق هر سال را می‌توانید در جدول زیر
                        مشاهده کنید. برای مثال، اگر حقوقی در سال ۹۸ ثبت شده باشد،‌ ابتدا به اندازه میزان افزایش حقوق سال ۹۹
                        و سپس به اندازه میزان افزایش حقوق سال ۱۴۰۰ افزایش می‌یابد. چنانچه حقوق شما متفاوت با این مقدار تغییر
                        کرده است از طریق ایمیل
                        {" "}
                        <a href="mailto:info@peydaa.ir">
                            info@peydaa.ir
                        </a>
                        {" "}
                        به ما اطلاع دهید.
                    </Typography>
                    <Container maxWidth={"xs"} className={classes.table}>
                        <TableContainer component={OutlinedPaper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            سال
                                        </TableCell>
                                        <TableCell>
                                            میزان افزایش حقوق
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {salaryUpdates.map((update) => (
                                        <TableRow key={update['id']}>
                                            <TableCell component={"th"} scope={"row"}>
                                                {persianJs(update['year'].toString()).englishNumber().toString()}
                                            </TableCell>
                                            <TableCell>
                                                {persianJs(update['change_percent'].toString()).englishNumber().toString()}
                                                {" "}
                                                درصد
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Container>
                </React.Fragment>
                }
                <br/><br/>

                <Typography variant="body1" component="p" align="justify">
                    در صورتی که پاسخ سوال خود را نگرفته‌اید با ما از طریق ایمیل
                    {" "}
                    <a href="mailto:info@peydaa.ir">
                        info@peydaa.ir
                    </a>
                    {" "}
                    در تماس باشید.
                </Typography>
            </div>
        </Container>
    )
}

export default FAQ;
