import {makeStyles, useTheme} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
import Toolbar from "@material-ui/core/Toolbar";
import logo from "../../logo.png";
import logoText from "../../text.png";
import React from "react";
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";
import {useMediaQuery} from "@material-ui/core";
import {HeaderMenu} from "./HeaderMenu";
import {HeaderItems} from "./HeaderItems";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        maxHeight: `calc(48px + ${theme.spacing(4)}px)`
    },
    appBar: {
        boxShadow: 'none',
        WebkitBoxShadow: 'none',
        MozBoxShadow: 'none',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    companies: {
        marginLeft: theme.spacing(2),
    },
    aboutUs: {
        marginLeft: 'auto',
    },
    buttonText: {
        textDecoration: 'none',
        color: theme.palette.text.primary,
    },
    logo: {
        height: '40px',
    },
    logoText: {
        height: '17px',
        marginLeft: theme.spacing(1),
        position: "relative",
        top: "50%",
        transform: "translateY(-50%)",
    }
}));

function Header(props) {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <div className={classes.root}>
            <AppBar color="transparent" position="static" className={classes.appBar}>
                <Container>
                    <Toolbar variant="dense">
                        <Link to="/">
                            <div>
                                <img src={logo} alt="پیدا" className={classes.logo} />
                                <img src={logoText} alt="پیدا" className={classes.logoText} />
                            </div>
                        </Link>
                        <Button color="inherit" className={classes.companies} size={"large"}>
                            <Link to="/companies" className={classes.buttonText}>
                                شرکت‌ها
                            </Link>
                        </Button>
                        <div className={classes.aboutUs}>
                            {matches ? <HeaderMenu/> : <HeaderItems/>}
                        </div>
                    </Toolbar>
                </Container>
            </AppBar>
        </div>
    )
}

export default Header;