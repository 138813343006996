import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Backdrop, CircularProgress, Container, useMediaQuery } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import TabPanel from "../../components/TabPanel";
import Overview from "./overview";
import Reviews from "./Reviews";
import Salaries from "./Salaries";
import { useParams } from "react-router-dom";
import { getCompany, getCompanyReviews, getCompanySalaries } from "../../api";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingBottom: theme.spacing(3),
        backgroundColor: theme.palette.grey[100],
        minHeight: 'calc(100vh - 48px - 32px - 68px)', // FIXME: this line may breaks every now and then
    },
    bar: {
        height: 100,
        position: "relative",
    },
    logoFrame: {
        background: "white",
        height: 120,
        width: 120,
        padding: '10px 10px',
        marginTop: -60,
        position: "relative",
    },
    companyLogo: {
        maxWidth: "100%",
        maxHeight: "100%",
        position: "relative",
        top: "50%",
        transform: "translateY(-50%)",
    },
    fullWidth: {
        width: '100%',
    },
    titleBar: {
        backgroundColor: theme.palette.background.default,
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function CompanyDetail() {
    const classes = useStyles();
    const [activeTab, setActiveTab] = useState(0);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const tabsVariant = matches ? "fullWidth" : "standard";
    const [company, setCompany] = useState({});
    const [reviews, setReviews] = useState([]);
    const [salaries, setSalaries] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const defaultColor = '#FFFFFF';
    let history = useHistory();
    let { slug, tab } = useParams();

    const tabMapping = {
        1: "salaries",
        2: "reviews",
    };

    useEffect(() => {
        if (tab === "salaries") {
            setActiveTab(1);
        } else if (tab === "reviews") {
            setActiveTab(2);
        } else {
            setActiveTab(0)
        }
    }, [tab,]);

    useEffect(() => {
        function fetchData() {
            return new Promise(resolve => {
                const p0 = getCompany(slug).then(data => setCompany(data));
                const p1 = getCompanyReviews(slug).then(data => setReviews(data));
                const p2 = getCompanySalaries(slug).then(data => setSalaries(data));
                Promise.all([p0, p1, p2]).then(resolve);
            });
        }
        fetchData().then(() => setDataLoaded(true));
    }, [slug,]);

    const handleTabChange = (event, newValue) => {
        if (newValue > 0) {
            history.push("/companies/" + slug + "/" + tabMapping[newValue]);
        } else {
            history.push("/companies/" + slug);
        }
    };

    const onButtonClick = () => {
        let queryParams = "";
        if (company["name_fa"]) {
            queryParams = "?company=" + company["name_fa"];
        }
        const path = encodeURI("/add" + queryParams);
        history.push(path);
    }

    const goToSalariesTab = () => {
        setActiveTab(1);
        window.scrollTo(0, 0);
    }
    const goToReviewsTab = () => {
        setActiveTab(2);
        window.scrollTo(0, 0);
    }

    const getPageTitle = () => {
        let res = 'شرکت ';
        res += company["name_fa"];
        res += " | پیدا";
        return res;
    }

    if (!dataLoaded) {
        return (
            <Backdrop className={classes.backdrop} open={true}>
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    }

    return (
        <div className={classes.root}>
            {company["name_fa"] &&
                <Helmet>
                    <title>
                        {getPageTitle()}
                    </title>
                </Helmet>
            }

            <div className={classes.bar}
                 style={{ backgroundColor: company["background_color"] ? company["background_color"] : defaultColor }} />
            <div className={classes.titleBar} style={{ paddingBottom: matches ? theme.spacing(2) : 0 }}>
                <Container maxWidth={"md"}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item>
                            <Paper variant={"outlined"} square className={classes.logoFrame}>
                                <img src={company["logo"]} alt={company["name_fa"]} className={classes.companyLogo} />
                            </Paper>
                        </Grid>
                        <Grid item>
                            <Typography variant={"h4"} component={"h4"}>
                                <b>
                                    {company["name_fa"]}
                                </b>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={10}>
                            <Tabs value={activeTab} onChange={handleTabChange} indicatorColor={"primary"}
                                  textColor={"primary"} variant={tabsVariant}>
                                <Tab label={"نمای کلی"} />
                                <Tab label={"حقوق‌ها"} />
                                <Tab label={"نظرات"} />
                            </Tabs>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Button variant={"contained"} color={"primary"} className={classes.fullWidth}
                                    onClick={onButtonClick}>
                                ثبت حقوق
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <Container maxWidth={"md"}>
                <TabPanel value={activeTab} index={0}>
                    <Overview company={company} reviews={reviews} salaries={salaries}
                              goToReviewsTab={goToReviewsTab} goToSalariesTab={goToSalariesTab} mobile={matches} />
                </TabPanel>
                <TabPanel value={activeTab} index={1}>
                    <Salaries company={company} salaries={salaries} mobile={matches} />
                </TabPanel>
                <TabPanel value={activeTab} index={2}>
                    <Reviews company={company} reviews={reviews} />
                </TabPanel>
            </Container>
        </div>
    )
}

export default CompanyDetail;