import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import React, {useState} from "react";
import {Redirect} from "react-router-dom";
import {CheckCircle} from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import classNames from "classnames";
import validateJS from "validate.js";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import {createSubscriber} from "../../api";
import {Helmet} from "react-helmet";
import {Backdrop, CircularProgress} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(6),
    },
    text: {
        marginTop: theme.spacing(3),
    },
    formControl: {
        marginTop: theme.spacing(3),
    },
    fullWidth: {
        width: '100%',
    },
    email: {
        flip: false,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function Success(props) {
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState(undefined);
    const [open, setOpen] = React.useState(false);
    const [backdropOpen, setBackdropOpen] = useState(false);

    const constraints = {
        email: {
            presence: {
                allowEmpty: false,
                message: "ایمیل نمی‌تواند خالی باشد",
            },
            email: {
                message: "ایمیل وارد شده درست نیست",
            },
        }
    };

    const validate = () => {
        const result = validateJS({
            email: email,
        }, constraints, {fullMessages: false});
        setErrors(result);
        return result === undefined;
    };

    const onSubmit = () => {
        if (!validate()) {
            return;
        }
        setBackdropOpen(true);
        createSubscriber({
            "email": email,
        }).then(data => {
            setBackdropOpen(false);
            setOpen(true);
        }).catch(err => {
            setBackdropOpen(false);
            console.log(err); // TODO
        });
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    if (!props.submitted) {
        return <Redirect to='/'/>
    }
    return (
        <div>
            <Helmet>
                <title>
                    مقایسه حقوق‌ها و شرکت‌ها | پیدا
                </title>
            </Helmet>

            <Backdrop className={classes.backdrop} open={backdropOpen}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Container maxWidth="md" className={classes.root}>
                <Typography variant="h1" component="div" align="center">
                    <CheckCircle fontSize="inherit" color="primary"/>
                </Typography>
                <Typography variant="h5" component="div" align="center">
                    <b>
                    از همکاری شما سپاسگزاریم!
                    </b>
                </Typography>

                <Typography variant="body1" component="p" align="center" className={classes.text}>
                    اطلاعات شما بررسی خواهند شد و در صورت تایید در پیدا قرار خواهد گرفت.
                    <br/>
                    اگر می‌خواهید از اخبار پیدا باخبر شوید لطفا ایمیل خود را وارد کنید.
                </Typography>
            </Container>

            <Container maxWidth="xs">
                <Grid container>
                    <Grid item xs={12}>
                        <TextField label="ایمیل" variant="outlined" size="small" type="email" id="email"
                                   fullWidth={true} className={classNames(classes.formControl, classes.email)}
                                   inputProps={{style: { direction: "ltr" }}}
                                   value={email}
                                   onChange={event => setEmail(event.target.value)}
                                   error={errors && 'email' in errors}
                                   helperText={(errors && errors.email) ? errors.email[0]:
                                       "ایمیل شما نزد پیدا محفوظ است و تنها برای اطلاع‌رسانی استفاده خواهد شد"
                                   }
                        />
                    </Grid>

                    <Grid item sm={4}/>
                    <Grid item xs>
                        <Button variant="contained" color="primary" onClick={onSubmit}
                                className={classNames(classes.formControl, classes.fullWidth)}
                        >
                            با خبرم کن
                        </Button>
                        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                            <Alert onClose={handleClose} severity="success">
                                ایمیل شما با موففیت ثبت شد!
                            </Alert>
                        </Snackbar>
                    </Grid>
                    <Grid item sm={4}/>
                </Grid>
            </Container>
        </div>
    )
}

export default Success;
