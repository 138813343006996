import {makeStyles} from "@material-ui/core/styles";
import {Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";
import Features from "./Features";
import Hero from '../../graphics.png';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2)
    },
    heroImage: {
        backgroundSize: 'contain',
        backgroundImage: `url(${Hero})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        width: '100%',
        height: '0',
        paddingTop: '80%',
    },
    title: {
        marginTop: theme.spacing(2)
    }
}));

function MainTitle(props) {
    const classes = useStyles();
    return (
        <Grid container alignItems="center" wrap="wrap-reverse" className={classes.root}>
            <Grid item xs={12} md={6}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h2" component="h2" className={classes.title}>
                            <b>
                                برای آینده شغلی خود بهتر تصمیم بگیرید
                            </b>
                        </Typography>
                    </Grid>
                    <Features/>
                </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
                <div className={classes.heroImage}/>
            </Grid>
        </Grid>
    )
}

export default MainTitle;
