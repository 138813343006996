import {makeStyles} from "@material-ui/core/styles";
import {Hidden, Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Company from "./Company";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import {getCompanies} from "../../api";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(6)
    },
    boxesRow: {
        marginTop: theme.spacing(2)
    },
    companiesText: {
        float: "left",
    },
    allCompanies: {
        // marginLeft: 'auto',
        float: "right",
    },
    allCompaniesText: {
        textDecoration: 'none',
        color: theme.palette.text.primary,
    }
}));

function Companies() {
    const classes = useStyles();
    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        getCompanies().then(data => setCompanies(() => {
            let a = []
            for (let i = 0; i < 3; i++) {
                a.push(Math.floor(Math.random() * data.length))
            }
            a.sort((a, b) => a-b)
            let res = []
            for (let i = 0; i < 3; i++) {
                res.push(data[a[i]])
            }
            return res
        }));
    }, []);

    return (
        <div className={classes.root}>
            <Typography variant="h4" component="span" className={classes.companiesText}>
                <b>
                    شرکت‌ها
                </b>
            </Typography>
            <span>
            <Button color="inherit" className={classes.allCompanies}>
                <Link to="/companies" className={classes.allCompaniesText}>
                    مشاهده همه
                </Link>
            </Button>
            </span>
            <Grid container spacing={2} className={classes.boxesRow}>
                <Grid item xs={12} sm={6} md={4}>
                    <Company company={companies[0]}/>
                </Grid>
                <Hidden xsDown>
                    <Grid item sm={6} md={4}>
                        <Company company={companies[1]}/>
                    </Grid>
                </Hidden>
                <Hidden smDown>
                    <Grid item md={4}>
                        <Company company={companies[2]}/>
                    </Grid>
                </Hidden>
            </Grid>
        </div>
    )
}

export default Companies;