import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import {FormControl} from "@material-ui/core";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import classNames from "classnames";
import FormToggleButton from "../FormToggleButton";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import React, {useEffect, useState} from "react";
import {getCompanyNames, getJobTitles, getLocations} from "../../api";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginTop: theme.spacing(3),
    },
    fullWidth: {
        width: '100%',
    },
    toggleButton: {
        width: '50%',
    },
}));

function SalaryForm(props) {
    const classes = useStyles();
    const [jobs, setJobs] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [locations, setLocations] = useState([]);

    useEffect(() => {
        getJobTitles().then(res => setJobs(res));
        getCompanyNames().then(res => setCompanies(res));
        getLocations().then(res => setLocations(res));
    }, []);

    return (
        <div>
            <Autocomplete
                freeSolo
                options={props.job.length < 3 ? [] :
                    jobs.map((option) => option['name_en']).concat(jobs.map((option) => option['name_fa']))}
                inputValue={props.job}
                onInputChange={(event, newValue) => props.setJob(newValue)}
                renderInput={(params) => (
                    <TextField {...params} label="شغل" fullWidth={true} variant="outlined"
                               className={classes.formControl} size="small"
                               helperText={(props.errors && props.errors.job) ? props.errors.job[0] :
                                   "برای مثال مهندس نرم‌افزار."
                               }
                               error={props.errors && 'job' in props.errors}
                    />
                )}
            />
            <Autocomplete
                freeSolo
                options={props.company.length < 3 ? [] : companies.map((option) => option['name_fa'])}
                inputValue={props.company}
                onInputChange={(event, newValue) => props.setCompany(newValue)}
                renderInput={(params) => (
                    <TextField {...params} label="شرکت" fullWidth={true} variant="outlined"
                               className={classes.formControl} size="small"
                               helperText={(props.errors && props.errors.company && props.errors.company[0])}
                               error={props.errors && 'company' in props.errors}
                    />
                )}
            />
            <TextField label="خالص حقوق دریافتی ماهانه" fullWidth={true} variant="outlined"
                       className={classes.formControl} size="small"
                       InputProps={{
                           endAdornment: <InputAdornment position="end" disableTypography>
                               <Typography color="textSecondary" variant="body2">
                                   میلیون تومان
                               </Typography>
                           </InputAdornment>,
                       }}
                       inputProps={{
                           inputMode: 'numeric',
                       }}
                       value={props.amount}
                       onChange={event => props.setAmount(event.target.value)}
                       helperText={(props.errors && props.errors.amount) ? props.errors.amount[0] :
                           "برای مثال اگر خالص حقوق دریافتی ماهانه شما ۵ میلیون تومان در ماه است، بنویسید ۵."
                       }
                       error={props.errors && 'amount' in props.errors}
            />

            <FormControl fullWidth={true} className={classes.formControl}
                         error={props.errors && 'isCurrentJob' in props.errors}
            >
                <ToggleButtonGroup size="small" exclusive
                                   className={classNames(classes.fullWidth)}
                                   value={props.isCurrentJob}
                                   onChange={(event, newValue) => props.setIsCurrentJob(newValue)}
                >
                    <FormToggleButton value={true} className={classes.toggleButton}>
                        شغل فعلی
                    </FormToggleButton>
                    <FormToggleButton value={false} className={classes.toggleButton}>
                        شغل سابق
                    </FormToggleButton>
                </ToggleButtonGroup>
                <FormHelperText>
                    {props.errors && props.errors.isCurrentJob && props.errors.isCurrentJob[0]}
                </FormHelperText>
            </FormControl>

            {(props.isCurrentJob === false) &&
            <FormControl variant="outlined" fullWidth={true} className={classes.formControl} size="small"
                         error={props.errors && 'lastYear' in props.errors}
            >
                <InputLabel>
                    آخرین سال فعالیت
                </InputLabel>
                <Select native label="آخرین سال فعالیت" value={props.lastYear}
                        onChange={event => props.setLastYear(event.target.value)}
                >
                    <option aria-label="None" value="" />
                    <option value={1400}>
                        ۱۴۰۰
                    </option>
                    <option value={1399}>
                        ۱۳۹۹
                    </option>
                    <option value={1398}>
                        ۱۳۹۸
                    </option>
                    <option value={1397}>
                        ۱۳۹۷
                    </option>
                    <option value={1396}>
                        ۱۳۹۶ یا قبل‌تر
                    </option>
                </Select>
                <FormHelperText>
                    {props.errors && props.errors.lastYear && props.errors.lastYear[0]}
                </FormHelperText>
            </FormControl>
            }

            <TextField label="سابقه کار مرتبط" fullWidth={true} variant="outlined"
                       className={classes.formControl} size="small"
                       InputProps={{
                           endAdornment: <InputAdornment position="end" disableTypography>
                               <Typography color="textSecondary" variant="body2">
                                   سال
                               </Typography>
                           </InputAdornment>,
                       }}
                       inputProps={{
                           inputMode: 'numeric',
                       }}
                       value={props.yearsOfExp}
                       onChange={event => props.setYearsOfExp(event.target.value)}
                       helperText={(props.errors && props.errors.yearsOfExp) ? props.errors.yearsOfExp[0] :
                           "برای مثال اگر ۲ سال سابقه کار مرتبط دارید، بنویسید ۲."
                       }
                       error={props.errors && 'yearsOfExp' in props.errors}
            />

            <Autocomplete
                freeSolo
                options={locations.map(option => option['city'])}
                inputValue={props.city}
                onInputChange={(event, newValue) => props.setCity(newValue)}
                renderInput={(params) => (
                    <TextField {...params} label="شهر" fullWidth={true} variant="outlined"
                               className={classes.formControl} size="small"/>
                )}
            />
        </div>
    )
}

export default SalaryForm;
