import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Review from "../../components/Review";
import Paper from "@material-ui/core/Paper";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import ReviewsTitle from "../../components/ReviewsTitle";
import {Helmet} from "react-helmet";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(3),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
    },
    alignLeft: {
        textAlign: "right",
    },
}));

function Reviews(props) {
    const classes = useStyles();

    const getPageTitle = () => {
        let res = 'نظرات شرکت ';
        res += props.company['name_fa'];
        res += ' | پیدا'
        return res;
    };

    return (
        <Paper variant={"outlined"} square className={classes.paper}>
            {props.company['name_fa'] &&
            <Helmet>
                <title>
                    {getPageTitle()}
                </title>
            </Helmet>
            }

            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Typography variant={"h5"} component={"h5"}>
                        <b>
                            نظرات
                        </b>
                    </Typography>
                </Grid>
                <Grid item xs={8} className={classes.alignLeft}>
                    <ReviewsTitle company={props.company}/>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                {props.reviews.map((review, i) =>
                    <React.Fragment key={i}>
                        <Grid item xs={12}>
                            <Review toolbar review={review} />
                        </Grid>
                        {(i < props.reviews.length - 1) &&
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        }
                    </React.Fragment>
                )}
            </Grid>
        </Paper>
    )
}

export default Reviews;