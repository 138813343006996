import {makeStyles} from "@material-ui/core/styles";
import {Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {CheckCircleOutline} from "@material-ui/icons";
import React from "react";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(4)
    },
    item: {
        padding: theme.spacing(0, 1, 0, 1)
    },
    iconVerticalAlign: {
        verticalAlign: "middle"
    }
}));

function Features(props) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={"auto"}>
                    <Typography variant="h5" component="h4" className={classes.item}>
                        <CheckCircleOutline fontSize="inherit" color="primary" className={classes.iconVerticalAlign}/>
                        {" "}
                        مقایسه حقوق‌ها
                    </Typography>
                </Grid>
                <Grid item xs={"auto"}>
                    <Typography variant="h5" component="h4" className={classes.item}>
                        <CheckCircleOutline fontSize="inherit" color="primary" className={classes.iconVerticalAlign}/>
                        {" "}
                        آشنایی با فضای کاری شرکت‌ها
                    </Typography>
                </Grid>
            </Grid>
        </div>
    )
}

export default Features;