import {Backdrop, CircularProgress, Container} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Company from "./Company";
import {makeStyles} from "@material-ui/core/styles";
import {getCompanies} from "../../api";
import {Helmet} from "react-helmet";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function CompanyList() {
    const classes = useStyles();
    const [companies, setCompanies] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);

    useEffect(() => {
        getCompanies().then(data => setCompanies(data)).then(() => setDataLoaded(true));
    }, []);

    if (!dataLoaded) {
        return (
            <Backdrop className={classes.backdrop} open={true}>
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    }

    return (
        <Container maxWidth="md">
            <Helmet>
                <title>
                    شرکت‌ها | پیدا
                </title>
            </Helmet>

            <div className={classes.root}>
                <Grid container spacing={2}>
                    {companies.map((company, i) =>
                        <Grid item xs={12} key={i}>
                            <Company company={company}/>
                        </Grid>
                    )}
                </Grid>
            </div>
        </Container>
    )
}

export default CompanyList;