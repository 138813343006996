import Typography from "@material-ui/core/Typography";
import Rating from "@material-ui/lab/Rating";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import FlagIcon from '@material-ui/icons/Flag';
import moment from 'jalali-moment'
import persianJs from "persianjs";
import ToggleButton from "@material-ui/lab/ToggleButton";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    alpha,
    withStyles
} from "@material-ui/core";
import {createReviewHelpful, createReviewReport, deleteReviewHelpful} from "../api";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import validateJS from "validate.js";

const useStyles = makeStyles((theme) => ({
    pros: {
        marginTop: theme.spacing(1),
    },
    cons: {
        marginTop: theme.spacing(1),
    },
    toolbar: {
        marginTop: theme.spacing(2),
    },
    alignLeft: {
        float: "right",
    },
    iconVerticalAlign: {
        verticalAlign: "middle"
    }
}));

const HelpfulButton = withStyles((theme) => ({
    root: {
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.light,
        '&$selected': {
            color: theme.palette.primary.main,
            backgroundColor: alpha(theme.palette.primary.main, 0.20),
            '&:hover': {
                backgroundColor: alpha(theme.palette.primary.main, 0.22),
            },
        }
    },
    selected: {},
}))(ToggleButton);

function getDateLabel(date) {
    let m = moment.from(date, 'en');
    m.locale('fa');
    const yearFormat = m.year() < 1400 ? 'YY' : 'YYYY';
    const year = persianJs(m.format(yearFormat)).englishNumber().toString();
    const month = persianJs(m.format('MMMM')).englishNumber().toString();
    const day = persianJs(m.format('D')).englishNumber().toString();
    return `${day} ${month} ${year}`
}

function getReviewHelpfulStorageKey(review) {
    return `review-helpful-${review['id']}`;
}

function Review(props) {
    const classes = useStyles();
    const lastCommentLabel = props.lastComment ? "آخرین نظر - " : "";
    const employmentStatusLabel = props.review["is_current_employee"] === true ? "کارمند فعلی" : "کارمند سابق";
    const dateLabel = getDateLabel(props.review["created_at"]);
    const [helpfulCount, setHelpfulCount] = useState(props.review["helpful"] ? props.review["helpful"] : 0);
    const helpfulCountLabel = helpfulCount ?
        ("(" + persianJs(helpfulCount.toString()).englishNumber().toString() + ")") : "";
    const [isHelpful, setIsHelpful] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [reportText, setReportText] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [reportErrors, setReportErrors] = useState(undefined);

    const constrains = {
        text: {
            presence: {
                allowEmpty: false,
                message: "توضیح مشکل نمی‌تواند خالی باشد"
            }
        },
    };

    const validate = () => {
        const result = validateJS({
            text: reportText,
        }, constrains, {fullMessages: false});
        setReportErrors(result);
        return result === undefined;
    }

    useEffect(() => {
        if (props.review && localStorage.getItem(getReviewHelpfulStorageKey(props.review))) {
            setIsHelpful(true);
        }
        if (props.review) {
            setHelpfulCount(props.review["helpful"]);
        }
    }, [props.review, ]);

    const onHelpfulChange = () => {
        if (!isHelpful) {
            createReviewHelpful({
                "review": props.review["id"],
            }).then(data => {
                setIsHelpful(true);
                localStorage.setItem(getReviewHelpfulStorageKey(props.review), JSON.stringify(data));
                setHelpfulCount(helpfulCount + 1);
            });
        } else {
            const key = getReviewHelpfulStorageKey(props.review);
            const reviewHelpfulId = JSON.parse(localStorage.getItem(key))["id"];
            deleteReviewHelpful(reviewHelpfulId).then(data => {
                setIsHelpful(false);
                localStorage.removeItem(key);
                setHelpfulCount(helpfulCount - 1);
            });
        }
    };

    const handleDialogOpen = () => {
        setOpenDialog(true);
    }

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    const onReportSubmit = () => {
        if (!validate()) {
            return ;
        }
        createReviewReport({
            "review": props.review['id'],
            "text": reportText,
        }).then(() => {
            setOpenSnackBar(true);
            setReportText("");
            handleDialogClose();
        }).catch(err => {
            console.log(err); // TODO
        });
    };

    return (
        <div>
            <Typography variant={"body2"} component={"p"}>
                {lastCommentLabel}
                {dateLabel}
            </Typography>
            <Typography variant={"h6"} component={"p"}>
                {props.review["headline"]}
            </Typography>
            <div>
                {props.review["overall_rating"] &&
                <Rating name={"overall-rating"} value={props.review["overall_rating"]} size={"small"} readOnly
                        className={classes.iconVerticalAlign}/>
                }
                {" "}
                <Typography variant={"body2"} component={"span"}>
                    {employmentStatusLabel}
                    {" - "}
                    {props.review["job_category"] && props.review["job_category"]["name_fa"]}
                </Typography>
            </div>
            {props.review["pros"] &&
            <Typography variant={"body1"} component={"p"} className={classes.pros}>
                <b>
                    نکات مثبت:
                </b>
                <br/>
                {props.review["pros"]}
            </Typography>
            }
            {props.review["cons"] &&
            <Typography variant={"body1"} component={"p"} className={classes.cons}>
                <b>
                    نکات منفی:
                </b>
                <br/>
                {props.review["cons"]}
            </Typography>
            }
            {props.toolbar &&
            <div className={classes.toolbar}>
                <HelpfulButton value={"check"} size={"small"} selected={isHelpful} onChange={onHelpfulChange}>
                    مفید بود
                    {" "}
                    {helpfulCountLabel}
                </HelpfulButton>
                <div className={classes.alignLeft}>
                    <Button size={"small"} onClick={handleDialogOpen}>
                        <FlagIcon className={classes.iconVerticalAlign}/>
                        {" "}
                        گزارش مشکل
                    </Button>
                    <Dialog open={openDialog} onClose={handleDialogClose}>
                        <DialogTitle id="form-dialog-title">
                            گزارش مشکل
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                برای ما بنویسید که در این نظر چه مشکلی وجود دارد. ارائه جزییات بیشتر، به ما امکان بررسی سریع‌تر و دقیق‌تر مشکل را می‌دهد.
                            </DialogContentText>
                            <TextField label="توضیح مشکل" fullWidth={true} variant="outlined" size={"small"}
                                       value={reportText} onChange={event => setReportText(event.target.value)} multiline
                                       error={reportErrors && 'text' in reportErrors}
                                       helperText={reportErrors && reportErrors.text && reportErrors.text[0]}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleDialogClose}>
                                بازگشت
                            </Button>
                            <Button onClick={onReportSubmit} color="primary" variant={"contained"}>
                                ارسال
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Snackbar open={openSnackBar} autoHideDuration={3000} onClose={() => setOpenSnackBar(false)}>
                        <Alert onClose={() => setOpenSnackBar(false)} severity="success">
                            گزارش شما با موفقیت ثبت شد!
                        </Alert>
                    </Snackbar>
                </div>
            </div>
            }
        </div>
    )
}

export default Review;