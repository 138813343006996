import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Review from "../../../components/Review";
import persianJs from "persianjs";
import ReviewsTitle from "../../../components/ReviewsTitle";
import {Link} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(3),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
    },
    alignLeft: {
        textAlign: "right",
    },
    alignCenter: {
        textAlign: "center",
    },
}));

function Reviews(props) {
    const classes = useStyles();

    return (
        <Paper variant={"outlined"} square className={classes.paper}>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Typography variant={"h5"} component={"h5"}>
                        <b>
                            نظرات
                        </b>
                    </Typography>
                </Grid>
                <Grid item xs={8} className={classes.alignLeft}>
                    <ReviewsTitle company={props.company}/>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                {props.reviews.length > 0 &&
                <React.Fragment>
                    <Grid item xs={12}>
                        <Review lastComment review={props.reviews[0]}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider/>
                    </Grid>
                </React.Fragment>
                }
                <Grid item xs={12} className={classes.alignCenter}>
                    <Link component={"button"} variant={"body2"} color={"inherit"} onClick={props.goToReviewsTab}>
                        مشاهده همه
                        {" "}
                        {props.company["review_count"] !== undefined &&
                        persianJs(props.company["review_count"].toString()).englishNumber().toString()}
                        {" "}
                        نظر ثبت‌شده
                    </Link>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default Reviews;