import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import classNames from "classnames";
import FormToggleButton from "../FormToggleButton";
import Rating from "@material-ui/lab/Rating";
import TextField from "@material-ui/core/TextField";
import React from "react";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginTop: theme.spacing(3),
    },
    fullWidth: {
        width: '100%',
    },
    toggleButton: {
        width: '50%',
    },
    inGroup: {
        marginTop: theme.spacing(1),
    },
}));

function ReviewForm(props) {
    const classes = useStyles();
    return (
        <div>
            <Box component="div" borderColor="transparent" className={classes.formControl}>
                <Typography variant="body1">
                    آیا مایل هستید به کاربران درباره فضای کاری شرکت خود بگویید؟
                </Typography>
                <ToggleButtonGroup size="small" exclusive
                                   className={classNames(classes.inGroup, classes.fullWidth)}
                                   value={props.wantReview}
                                   onChange={(event, newValue) => props.setWantReview(newValue)}
                >
                    <FormToggleButton value={true} className={classes.toggleButton}>
                        بله
                    </FormToggleButton>
                    <FormToggleButton value={false} className={classes.toggleButton}>
                        خیر، شاید بعدا
                    </FormToggleButton>
                </ToggleButtonGroup>
            </Box>

            {(props.wantReview === true) &&
            <div>
                <FormControl fullWidth={true} className={classes.formControl}
                             error={props.errors && 'rating' in props.errors}
                >
                    <Typography variant="body1">
                        امتیاز
                    </Typography>
                    <Rating name="simple-controlled" size="large"
                            value={props.rating}
                            onChange={(event, newValue) => props.setRating(newValue)}
                    />
                    <FormHelperText>
                        {props.errors && props.errors.rating && props.errors.rating[0]}
                    </FormHelperText>
                </FormControl>
                <TextField label="عنوان نظر شما" fullWidth={true} variant="outlined"
                           className={classes.formControl} size="small"
                           value={props.reviewHeadline}
                           onChange={event => props.setReviewHeadline(event.target.value)}
                           error={props.errors && 'reviewHeadline' in props.errors}
                           helperText={props.errors && props.errors.reviewHeadline && props.errors.reviewHeadline[0]}
                />
                <TextField label="نکات مثبت" fullWidth={true} variant="outlined"
                           multiline className={classes.formControl} size="small"
                           value={props.pros}
                           onChange={event => props.setPros(event.target.value)}
                />
                <TextField label="نکات منفی" fullWidth={true} variant="outlined"
                           multiline className={classes.formControl} size="small"
                           onChange={event => props.setCons(event.target.value)}
                />
            </div>
            }
        </div>
    )
}

export default ReviewForm;
